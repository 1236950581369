import styles from "./styles.module.scss";

const MoreCount = ({ count }) => {
  return (
    <div className={styles.root}>
      <div className={styles.filter} />
      <span className={styles.count}>+{count}</span>
    </div>
  );
};

export default MoreCount;
