import { createSlice } from "@reduxjs/toolkit";

const storesSlice = createSlice({
  name: "stores",
  initialState: 0,
  reducers: {
    act: (state, action) => state + (action.payload || 1),
  },
});

export const { act } = storesSlice.actions;
export default storesSlice.reducer;

export const selectStoresState = (state) => state.stores;
