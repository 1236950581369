import Checkbox from "ui/Checkbox";

import Icon from "./components/Icon";
import CheckedIcon from "./components/CheckedIcon";

const NumberedCheckbox = ({ orderNumber, color = "primary", ...props }) => {
  return (
    <Checkbox
      icon={<Icon color={color} />}
      checkedIcon={<CheckedIcon orderNumber={orderNumber} color={color} />}
      {...props}
    />
  );
};

export default NumberedCheckbox;
