import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import BaseDialog from "ui/Dialog";
import IconButton from "ui/IconButton";
import { createComponentsMap } from "utils/components";

import { selectUiDialogState, hideDialog } from "store/ducks/ui/dialog";

import { CONTENT_MAP } from "./helpers";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const getContentComponent = createComponentsMap(CONTENT_MAP);

const Dialog = () => {
  const dispatch = useDispatch();

  const {
    isOpen,
    content,
    data = {},
    props = {},
  } = useSelector(selectUiDialogState);

  const [Content] = useMemo(() => getContentComponent(content), [content]);

  const { hideCloseButton = false, ...restProps } = props;

  const handleModalClose = () => dispatch(hideDialog());

  return (
    <BaseDialog
      onClose={handleModalClose}
      aria-labelledby="template-dialog"
      open={isOpen}
      fullWidth
    >
      {!hideCloseButton && (
        <IconButton
          aria-label="info"
          className="p-0 absolute top-4 right-4 text-base-1000 z-10"
          onClick={handleModalClose}
        >
          <CloseIcon />
        </IconButton>
      )}
      <Content data={data} {...restProps} />
    </BaseDialog>
  );
};

export default Dialog;
