import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { useGetStoresQuery } from "services/stores";

import Card from "components/Card";
import Playlist from "components/Playlist";

import { PAGE_TYPES } from "constants/index";
import { replaceParams } from "utils/router";

import pages from "router/links";

const Stores = () => {
  const navigate = useNavigate();
  const { data: { data: storesData = [] } = {}, isLoading } =
    useGetStoresQuery();

  useEffect(() => {
    if (storesData.length === 1) {
      const params = {
        storeId: storesData[0].id,
        pageType: PAGE_TYPES.MAIN,
      };
      navigate(replaceParams(pages.store.path, params), { replace: true });
    }
  }, [navigate, storesData]);

  // label SKELETON
  if (isLoading) {
    return (
      <div className="flex flex-col gap-4">
        {Array.from({ length: 5 }, () => ({ uuid: uuidv4() })).map(() => (
          <Playlist.Skeleton />
        ))}
      </div>
    );
  }

  // label STORES LIST
  return (
    <div className="grid grid-cols-2 gap-x-2 gap-y-8">
      {storesData.map(({ id, name, address }) => (
        <Card
          key={id}
          title={name}
          description={address}
          component={NavLink}
          to={`${pages.stores.path}/${id}`}
        />
      ))}
    </div>
  );
};

export default Stores;
