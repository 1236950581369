import List from "ui/List";
import ListItem from "ui/ListItem";
import ListItemButton from "ui/ListItemButton";
import ListItemIcon from "ui/ListItemIcon";
import ListItemText from "ui/ListItemText";

const ActionsMenu = ({ data = {} }) => {
  const { menuItems } = data;

  return (
    <List>
      {menuItems.map(({ icon: Icon, label, color, onClick }) => (
        <ListItem key={label}>
          <ListItemButton className={color} onClick={(e) => onClick(e, data)}>
            <ListItemIcon>
              <Icon className="h-5" />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default ActionsMenu;
