import { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { useGetStoreQuery } from "services/stores";

import BaseTemplate from "templates/BaseTemplate";
import CommonTemplate from "templates/CommonTemplate";
import Playlist from "components/Playlist";
import SkeletonWrapper from "components/SkeletonWrapper";

import { PAGE_TYPES } from "constants/index";
import { plural } from "utils";

const Store = () => {
  const { storeId, pageType } = useParams();
  const {
    data: storeData = {},
    isLoading,
    refetch,
  } = useGetStoreQuery(storeId);

  const { name, address, devices = [] } = storeData;

  useEffect(() => {
    refetch();
  }, [refetch]);

  const WrapperComponent =
    pageType === PAGE_TYPES.MAIN ? BaseTemplate : CommonTemplate;

  return (
    <WrapperComponent
      headerProps={{
        title: name,
        description: address,
      }}
    >
      <div>
        {(isLoading
          ? Array.from({ length: 3 }, () => ({ uuid: uuidv4() }))
          : devices
        ).map(
          ({
            uuid,
            id = uuid,
            name,
            playlists: { data: playlistsData = [], active } = {},
            label,
          }) => {
            const playlistCount = playlistsData.length;
            const showPlaylist = active ?? playlistsData[0];

            return (
              <SkeletonWrapper
                key={id}
                condition={isLoading}
                Component={Playlist.Skeleton}
              >
                <Playlist
                  label={!!active && "Active"}
                  name={name}
                  videos={showPlaylist?.previews}
                  component={NavLink}
                  to={`screens/${id}`}
                  footerRightContent={
                    <span className="text-base-500">
                      ({playlistCount} {plural("Playlist", playlistCount)})
                    </span>
                  }
                />
              </SkeletonWrapper>
            );
          }
        )}
      </div>
    </WrapperComponent>
  );
};

export default Store;
