export const COOKIE_TOKEN_NAME = "token";
export const COOKIE_USER = "user";

export const VIDEO_AD_DURATION_SLOT = 7.5;
export const MAX_VIDEO_AD_DURATION = 60;

export const VIEWS = {
  LIST: "LIST",
  CARD: "CARD",
};

export const MODE = {
  VIEW: "VIEW",
  EDIT: "EDIT",
};

export const PRODUCT_VIEWS = {
  SCANNER: "SCANNER",
  CATALOG: "CATALOG",
};

export const PAGE_TYPES = {
  MAIN: "main",
};
