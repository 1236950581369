import IconButton from "ui/IconButton";
import Skeleton from "ui/Skeleton";

import { ReactComponent as ReselectIcon } from "assets/icons/reselect.svg";

const TemplateSelect = ({ id, name, img, onChangeTemplate }) => {
  return (
    <div className="flex gap-6 justify-between bg-base-50 rounded-2xl p-1">
      <div className="flex gap-6">
        <img
          src={img}
          alt="template"
          className="w-16 h-16 object-cover rounded-lg"
        />
        <div className="flex flex-col gap-1 justify-center">
          <span className="leading-none line-clamp-1">Template in Use</span>
          <span className="text-base-500 text-sm leading-none line-clamp-1">
            {name}
          </span>
        </div>
      </div>
      <div className="flex justify-center items-center pr-3 pl-4 relative before:content-[''] before:absolute before:bg-base-1000/[.1] before:left-0 before:top-5 before:bottom-5 before:w-[1px]">
        <IconButton
          aria-label="reselect"
          className="text-brand-500"
          onClick={onChangeTemplate}
        >
          <ReselectIcon />
        </IconButton>
      </div>
    </div>
  );
};

TemplateSelect.Skeleton = () => <Skeleton.Round height={72} />;

export default TemplateSelect;
