import { Route } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import Noop from "components/Noop";

export const createRoutes = (pages, links) =>
  Object.entries(pages).map(([k, v]) => {
    const {
      main: Component = Noop,
      template: Template = Noop,
      templateProps,
      routes: vRoutes,
      ...restV
    } = v;
    const { routes, ...restLinks } = links[k] || {};

    const Comp = () => (
      <Template {...templateProps}>
        <Component pageMeta={restLinks} />
      </Template>
    );

    if (v.routes) {
      const children = createRoutes(v.routes, routes);
      return {
        ...restV,
        ...restLinks,
        Component: Comp,
        children,
      };
    }

    return {
      ...restV,
      ...restLinks,
      Component: Comp,
    };
  });

const getRouteItem = ({
  name,
  path,
  element,
  children,
  status = "private",
}) => {
  if (status === "private") {
    return (
      <Route key={name} element={<PrivateRoute />}>
        <Route path={path} element={element}>
          {children?.map((r) => getRouteItem(r))}
        </Route>
      </Route>
    );
  }
  if (status === "public") {
    return (
      <Route key={name} exact path={path} element={element}>
        {children?.map((r) => getRouteItem(r))}
      </Route>
    );
  }
  throw new Error("Incorrect status of the Route");
};

export const getRoutes = (router) =>
  router?.map((route) => getRouteItem(route));

export const replaceParams = (path, params) =>
  path
    .replace(/:([^/?]+)\??/g, (_, key) => {
      return params[key] || "";
    })
    .replace(/\/$/, "");
