import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useSearch = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getInitialSearchTerm = () => {
    const params = new URLSearchParams(location.search);
    return params.get("search") || "";
  };

  const [searchTerm, setSearchTerm] = useState(getInitialSearchTerm);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (searchTerm) {
      params.set("search", searchTerm);
    } else {
      params.delete("search");
    }
    navigate({ search: params.toString() }, { replace: true });
  }, [searchTerm, navigate, location.search]);

  return [searchTerm || undefined, setSearchTerm];
};

export default useSearch;
