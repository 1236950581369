import Cookies from "js-cookie";
import { COOKIE_TOKEN_NAME } from "constants/index";

const getIdentity = (token) => {
  const _token = token || Cookies.get(COOKIE_TOKEN_NAME);
  if (_token) {
    const parts = _token?.split(".");
    const decodedPayload = JSON.parse(atob(parts?.[1]));

    return {
      id: decodedPayload?.id,
      name: decodedPayload?.name,
      phone: decodedPayload?.phone,
      avatar: "",
      email: decodedPayload?.email,
    };
  }

  return { user: {} };
};

export default getIdentity;
