import { useCallback, useRef } from "react";

const useDebounce = (delay) => {
  const timerRef = useRef(null);

  return useCallback(
    (callback) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        callback();
      }, delay);
    },
    [delay]
  );
};

export default useDebounce;
