import { forwardRef, createElement, cloneElement, isValidElement } from "react";
import Noop from "components/Noop";

export const createBaseProxy = (
  Component,
  name = "ProxyComponent",
  options = {}
) => {
  const Proxy = forwardRef(({ children, ...props }, ref) => (
    <Component {...options} {...props} ref={ref}>
      {children}
    </Component>
  ));
  Proxy.displayName = name;
  return Proxy;
};

export const renderComponent = (content, props = {}) => {
  if (content == null) {
    return null;
  } else if (isValidElement(content)) {
    return cloneElement(content, props);
  } else if (
    typeof content === "function" ||
    typeof content?.$$typeof === "symbol"
  ) {
    return createElement(content, props);
  } else {
    console.error("Invalid content type:", content);
    return null;
  }
};

export const getComponent = (componentsMap, key) => {
  return componentsMap[key] ?? componentsMap["default"] ?? (() => {});
};

export const getMapValue = (map, key, defaultValue) =>
  map.get(key) || map.get(defaultValue) || null;

export const createComponentsMap = (object = {}, defaultTemplate) => {
  return (key) => {
    const map = new Map(Object.entries(object));
    const Component = getMapValue(map, key, defaultTemplate) || Noop;

    return [Component, Array.from(map?.keys()), map];
  };
};
