import Link from "ui/Link";
import Title from "components/Title";

import { EMAIL } from "constants/APIs";

const ContactForm = () => {
  return (
    <div className="flex flex-1 flex-col gap-4 justify-center items-center">
      <div className="flex flex-col gap-2 items-center">
        <Title size="2xl">We're Here to Help!</Title>
        <span className="text-sm text-base-500 max-w-[60%] text-center">
          Need support? Discovered a bug? Have a suggestion? Email us at:
        </span>
      </div>
      <Link href={`mailto:${EMAIL}`} className="text-[#131313] no-underline">
        {EMAIL}
      </Link>
    </div>
  );
};

export default ContactForm;
