import Drawer from "components/Global/Drawer";
import Dialog from "components/Global/Dialog";

const AbsoluteComponents = () => {
  return (
    <>
      <Drawer />
      <Dialog />
    </>
  );
};

export default AbsoluteComponents;
