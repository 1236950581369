import MuiTextField from "@mui/material/TextField";
import { createBaseProxy } from "utils/components";

import withInputLabel from "HOCs/withInputLabel";

const Input = createBaseProxy(MuiTextField, "Input", {
  variant: "filled",
});

Input.Label = withInputLabel(Input);

export default Input;
