import { useMemo } from "react";
import { NavLink, useParams } from "react-router-dom";
import pages from "router/links";

import { useGetStoresQuery } from "services/stores";

import CommonTemplate from "templates/CommonTemplate";
import Section from "components/Section";
import Button from "ui/Button";

import Card from "pages/AssignScreens/components/Card";

const AssignScreens = ({ headerProps, playlistId: playlistIdProps }) => {
  const { playlistId: playlistIdParams } = useParams();
  const playlistId = playlistIdParams ?? playlistIdProps;

  const { data: { data: storesData = [] } = {} } = useGetStoresQuery();

  const stores = useMemo(
    () => storesData.filter(({ devices = [] }) => devices.length),
    [storesData]
  );

  return (
    <CommonTemplate headerProps={headerProps}>
      <div className="flex flex-col flex-1 gap-4 justify-between">
        <div className="flex flex-col gap-12">
          {stores.map(({ id, name, devices }) => (
            <Section key={id} title={name}>
              <div className="flex flex-col gap-2">
                {devices.map(({ name: deviceName, id }, i) => (
                  <Card
                    key={id}
                    id={id}
                    playlistId={playlistId}
                    title={`Screen ${i + 1}`}
                    name={deviceName}
                  />
                ))}
              </div>
            </Section>
          ))}
        </div>
        <Button
          variant="contained"
          size="small"
          component={NavLink}
          to={pages.playlists.path}
          className="sticky bottom-4"
        >
          Go to playlists
        </Button>
      </div>
    </CommonTemplate>
  );
};

export default AssignScreens;
