import { useForm } from "react-hook-form";

import Dropzone from "components/Dropzone";

import Button from "ui/Button";
import Slider from "ui/Slider";

import { MAX_VIDEO_AD_DURATION, VIDEO_AD_DURATION_SLOT } from "constants/index";
import { FILE_NAME } from "constants/ads";

const marksCount = Math.ceil(MAX_VIDEO_AD_DURATION / VIDEO_AD_DURATION_SLOT);

const marks = Array.from({ length: marksCount }).map((_, i) => {
  const value = VIDEO_AD_DURATION_SLOT + i * VIDEO_AD_DURATION_SLOT;
  return {
    value: value,
    label: value,
  };
});

const AdUploadFile = ({ data: { onSubmit } }) => {
  const { register, handleSubmit, setValue, watch } = useForm();

  const isDisableButton = !watch(FILE_NAME);

  const handleDrop = (files) => {
    setValue(FILE_NAME, files[0]);
  };

  const handleDelete = () => {
    setValue(FILE_NAME, null);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <span className="text-sm text-base-500">Upload Files (jpeg, png)</span>
        <Dropzone onDrop={handleDrop} onDelete={handleDelete} />
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-sm text-base-500">Ad Duration (sec)</span>
        <div className="px-2">
          <Slider
            {...register("seconds", { valueAsNumber: true })}
            aria-label="Duration"
            min={VIDEO_AD_DURATION_SLOT}
            max={MAX_VIDEO_AD_DURATION}
            step={VIDEO_AD_DURATION_SLOT}
            marks={marks}
          />
        </div>
      </div>
      <Button
        variant="contained"
        fullWidth
        type="submit"
        disabled={isDisableButton}
      >
        Next
      </Button>
    </form>
  );
};

export default AdUploadFile;
