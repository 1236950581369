import { forwardRef } from "react";

import InputLabel from "ui/InputLabel";

const withInputLabel = (Input) => {
  return forwardRef((props, ref) => {
    const { label, inputProps, ...restProps } = props;

    return (
      <div className="flex flex-col gap-2">
        <InputLabel size="small">{label}</InputLabel>
        <Input
          {...restProps}
          inputRef={ref}
          inputProps={{
            ...inputProps,
            sx: { paddingTop: "24px", paddingBottom: "24px" },
          }}
        />
      </div>
    );
  });
};

export default withInputLabel;
