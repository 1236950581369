import MuiCircularProgress from "@mui/material/CircularProgress";

import { createBaseProxy } from "utils/components";

const CircularProgress = createBaseProxy(
  MuiCircularProgress,
  "CircularProgress"
);

export default CircularProgress;
