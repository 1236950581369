import cx from "classnames";

import Button from "ui/Button";
import IconButton from "ui/IconButton";
import FormHelperText from "ui/FormHelperText";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as ScanIcon } from "assets/icons/scan.svg";

import colors from "themes/palette/colors.scss";

const ProductSelectActions = ({ error, helperText, onScan, onSelect }) => (
  <div>
    <div className="flex gap-2">
      <Button
        variant="dashed"
        fullWidth
        startIcon={
          <PlusIcon className={cx("w-4", { "text-common-error": error })} />
        }
        sx={{
          borderColor: error ? colors["error"] : "inherit",
        }}
        color="error"
        onClick={onSelect}
      >
        <span className={cx({ "text-common-error": error })}>
          Select a Product
        </span>
      </Button>
      <IconButton
        aria-label="scan"
        variant="contained"
        color="primary"
        onClick={onScan}
      >
        <ScanIcon />
      </IconButton>
    </div>
    <FormHelperText error={error} className="ml-3.5">
      {helperText}
    </FormHelperText>
  </div>
);

export default ProductSelectActions;
