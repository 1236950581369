import colors from "./colors.scss";

export const getDefaultPrimary = () => ({
  main: colors["brand-500"],
});

export const getDefaultPrimaryInvert = () => ({
  main: colors["base-0"],
  contrastText: colors["brand-600"],
});

export const getDefaultSecondary = () => ({
  main: colors["base-0"],
  contrastText: colors["brand-500"],
});

// export const getDefaultSecondaryInvert = () => ({
// main: colors["base-0"],
// contrastText: colors["brand-600"],
// });

export const getDefaultError = () => ({
  main: colors["error"],
});

export const getDefaultSuccess = () => ({
  main: colors["success"],
});
