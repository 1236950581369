import { COLORS } from "../../helpers";

const Icon = ({ color = "primary" }) => {
  const colorClasses = color === "primary" ? COLORS.primary : COLORS.secondary;

  return (
    <div
      className={`flex w-5 h-5 rounded-full border-2 ${colorClasses.icon}`}
    />
  );
};

export default Icon;
