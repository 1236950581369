import IconButton from "ui/IconButton";

import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const PreviewDropzone = ({ getRootProps, text, file, onDelete }) => {
  if (file) {
    return (
      <div className="relative flex border border-solid border-[#C7C7D1] border-opacity-50 bg-base-50 p-2 rounded-xl h-[210px]">
        <img
          src={file?.preview}
          alt="New product"
          className="h-full w-full object-contain"
        />
        <IconButton
          aria-label="info"
          className="p-0 absolute top-2 right-2 text-base-300 z-10"
          onClick={onDelete}
        >
          <CloseIcon />
        </IconButton>
      </div>
    );
  }

  return (
    <div
      {...getRootProps()}
      className="flex flex-col justify-center rounded-xl border border-dashed border-gray-200 px-6 py-4 min-h-[80px] cursor-pointer"
    >
      <div className="flex gap-4 items-center justify-center text-center">
        <UploadIcon className="w-4 shrink-0" />
        <span>{text}</span>
      </div>
    </div>
  );
};

export default PreviewDropzone;
