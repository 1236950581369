import { plural } from "utils";
import { v4 as uuidv4 } from "uuid";

import Skeleton from "ui/Skeleton";
import CircularProgress from "ui/CircularProgress";

const Mini = ({
  id,
  name,
  videos = [],
  component,
  componentProps = {},
  loading = false,
}) => {
  const Component = component || "div";
  const { onClick, disabled, ...componentPropsRest } = componentProps;

  const countVideo = videos.length;
  const limitedItems = videos.slice(0, 2);
  const isShowEmpty = countVideo < 2;

  return (
    <Component
      className="flex flex-col gap-3 items-start overflow-hidden rounded-none"
      disabled={disabled || loading}
      onClick={(e) => onClick?.(e, { id, name })}
      {...componentPropsRest}
    >
      <div className="grid grid-cols-2 gap-1 min-w-full">
        {limitedItems.map((img, index) => (
          <div key={index} className="w-auto relative">
            <img
              src={img}
              alt=""
              className="w-full aspect-[60/128] object-cover rounded-lg"
            />
          </div>
        ))}
        {isShowEmpty && (
          <div className="w-full h-full border border-dashed border-base-200 rounded-lg" />
        )}
      </div>
      <div className="flex flex-col gap-1 text-start w-full">
        <span className="font-medium text-base-1000 truncate leading-tight">
          {name}
        </span>
        <span className="text-sm text-base-500 leading-tight truncate">
          {countVideo} {plural("Video", countVideo)}
        </span>
      </div>
      {loading && (
        <>
          <div className="absolute rounded-lg inset-0 bg-black opacity-25 backdrop-blur-sm"></div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <CircularProgress />
          </div>
        </>
      )}
    </Component>
  );
};

Mini.Skeleton = () => (
  <div className="flex flex-col gap-3">
    <div className="grid grid-cols-2 gap-1 min-w-full">
      {Array.from({ length: 2 }, () => ({ uuid: uuidv4() })).map(({ uuid }) => (
        <Skeleton.Round
          key={uuid}
          sx={{
            aspectRatio: "9 / 16",
            width: "100%",
            height: "auto",
          }}
        />
      ))}
    </div>
    <div className="flex flex-col gap-1 text-start w-full">
      <Skeleton width="55%" sx={{ fontSize: "1rem" }} />
      <Skeleton width="35%" sx={{ fontSize: "1rem" }} />
    </div>
  </div>
);

export default Mini;
