import cx from "classnames";

import CommonTemplate from "templates/CommonTemplate";

import Dropzone from "components/Dropzone";
import PreviewDropzone from "components/Dropzone/PreviewDropzone";

import Button from "ui/Button";
import Input from "ui/Input";
import { ReactComponent as ScanIcon } from "assets/icons/scan.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";

import { BARCODE_FIELD, IMAGE_FIELD } from "../../helpers";

const FORM_FIELDS = [
  {
    name: "brand",
    label: "Brand",
    required: true,
  },
  {
    name: "name",
    label: "Name of Product",
    required: true,
  },
  {
    name: "volume",
    label: "Volume (litres)",
    required: false,
  },
];

const Form = ({
  loading,
  headerProps,
  register,
  errors,
  watch,
  getValues,
  onScan,
  onDrop,
  onDelete,
}) => {
  const isBarcode = !!watch(BARCODE_FIELD);
  const isImage = !!watch(IMAGE_FIELD);

  const isButtonDisabled = !isBarcode || !isImage || loading;

  const renderBarcode = () => {
    if (isBarcode) {
      return (
        <Input
          {...register(BARCODE_FIELD)}
          autoComplete="off"
          label="Barcode"
          type="text"
          helperText={errors[BARCODE_FIELD]?.message}
          error={!!errors[BARCODE_FIELD]}
        />
      );
    }

    return (
      <Button
        variant="dashed"
        startIcon={<ScanIcon className="w-4" />}
        className={cx("h-12 border-base-500 min-h-20")}
        onClick={onScan}
      >
        Scan product
      </Button>
    );
  };

  return (
    <CommonTemplate headerProps={headerProps}>
      <div className="flex flex-1 flex-col justify-between gap-2">
        <div className="flex flex-col gap-2">
          {FORM_FIELDS.map(({ name, label, required }) => (
            <Input
              key={name}
              {...register(name)}
              autoComplete="off"
              label={label}
              type="text"
              helperText={errors[name]?.message}
              error={!!errors[name]}
            />
          ))}
          {renderBarcode()}
          <div className="flex flex-col gap-2">
            <span className="text-sm text-base-500">
              Upload Files (jpeg, png)
            </span>
            <Dropzone
              initFile={getValues(IMAGE_FIELD)}
              text="Upload a picture of the front of the bottle"
              Component={PreviewDropzone}
              onDrop={onDrop}
              onDelete={onDelete}
            />
          </div>
        </div>
        <Button.Loading
          variant="contained"
          loading={loading}
          fullWidth
          classes={{ root: "sticky bottom-6" }}
          disabled={isButtonDisabled}
          startIcon={<PlusIcon className="w-4" />}
          type="submit"
        >
          Add Product
        </Button.Loading>
      </div>
    </CommonTemplate>
  );
};

export default Form;
