import Button from "ui/Button";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as PlaylistIcon } from "assets/icons/playlist.svg";

const FooterActions = ({ isLoading, onAddToPlaylist, onSave }) => (
  <>
    <Button
      variant="contained"
      startIcon={<PlaylistIcon className="w-4" />}
      fullWidth
      onClick={onAddToPlaylist}
    >
      Add to Playlist
    </Button>
    <Button.Loading
      variant="contained"
      loading={isLoading}
      startIcon={<PlusIcon className="w-4" />}
      fullWidth
      onClick={onSave}
    >
      Save to My Ads
    </Button.Loading>
  </>
);

export default FooterActions;
