import { COLORS } from "../../helpers";

const CheckedIcon = ({ orderNumber = null, color = "primary" }) => {
  const colorClasses = color === "primary" ? COLORS.primary : COLORS.secondary;

  return (
    <div
      className={`flex justify-center items-center text-sm leading-none relative w-5 h-5 rounded-full after:absolute after:content-[''] after:-inset-0.5 after:border after:rounded-full ${colorClasses.checkedIcon}`}
    >
      {orderNumber}
    </div>
  );
};

export default CheckedIcon;
