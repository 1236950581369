import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import useSearch from "hooks/useSearch";
import useDebounce from "hooks/useDebounce";

import { hideDialog, showDialog } from "store/ducks/ui/dialog";

import Skeleton from "ui/Skeleton";
import Button from "ui/Button";

import SearchInput from "components/SearchInput";
import Section from "components/Section";
import MediaCard from "components/MediaCard";
import LinearSwiper from "components/LinearSwiper";
import SkeletonWrapper from "components/SkeletonWrapper";
import ComponentFallbackRenderer from "components/ComponentFallbackRenderer";

import {
  useGetTemplatesListQuery,
  useGetTemplatesQuery,
} from "services/templates";

import { CATEGORIES } from "constants/categories";
import { TEMPLATE_CONTENT } from "constants/dialog";
import { TEMPLATE_ID } from "constants/searchParams";

import pages from "router/links";
import { useState } from "react";

const PAGINATION = {
  limit: 10,
  page: 1,
};

// todo code refactoring
const List = ({ isSearch, isFetching, data, onCardClick }) => {
  const templatesData = (isSearch ? data?.data : data) || [];

  if (isSearch) {
    return (
      <ComponentFallbackRenderer data={templatesData} isLoading={isFetching}>
        {(data) => (
          <div className="grid grid-cols-3 gap-x-2 gap-y-4">
            {(isFetching
              ? Array.from({ length: 9 }, () => ({ uuid: uuidv4() }))
              : data
            ).map(({ uuid, id = uuid, name, products_count, preview_url }) => (
              <SkeletonWrapper
                key={id}
                condition={isFetching}
                Component={MediaCard.Skeleton}
              >
                <MediaCard
                  key={id}
                  title={name}
                  img={preview_url}
                  aspectRatio="auto"
                  Description={
                    <span className="text-base-500 text-sm">
                      Products: {products_count || 1}
                    </span>
                  }
                  component={Button}
                  componentProps={{
                    onClick: (e) => {
                      onCardClick(e, {
                        id,
                        name,
                        productsCount: products_count,
                        img: preview_url,
                      });
                    },
                  }}
                />
              </SkeletonWrapper>
            ))}
          </div>
        )}
      </ComponentFallbackRenderer>
    );
  }

  return (
    isFetching
      ? Array.from({ length: 3 }, () => ({ uuid: uuidv4() }))
      : templatesData
  ).map(({ uuid, category = uuid, templates }) => (
    <Section
      key={category}
      title={
        isFetching ? (
          <Skeleton width={150} sx={{ fontSize: "1.5rem" }} />
        ) : (
          CATEGORIES[category]?.label
        )
      }
      buttonLabel="View all"
      buttonProps={{
        component: NavLink,
        to: `${pages.templates.path}/${category}`,
      }}
    >
      <LinearSwiper
        slides={
          isFetching
            ? Array.from({ length: 3 }, () => ({ uuid: uuidv4() }))
            : templates
        }
        className="-mr-4"
        sliderProps={{
          slidesOffsetAfter: 16,
        }}
      >
        {({ uuid, id = uuid, name, products_count, preview_url }) => (
          <SkeletonWrapper
            key={id}
            condition={isFetching}
            Component={MediaCard.Skeleton}
          >
            <MediaCard
              title={name}
              img={preview_url}
              aspectRatio="auto"
              Description={
                <span className="text-base-500 text-sm">
                  Products: {products_count || 1}
                </span>
              }
              className="min-w-full"
              imageClassName="aspect-[9/16]"
              component={Button}
              componentProps={{
                onClick: (e) => {
                  onCardClick(e, {
                    id,
                    name,
                    productsCount: products_count,
                    img: preview_url,
                  });
                },
              }}
            />
          </SkeletonWrapper>
        )}
      </LinearSwiper>
    </Section>
  ));
};

const Templates = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(PAGINATION.page);

  const [search, setSearch] = useSearch();
  const debouncedSearch = useDebounce(700);

  const templatesRequest = !!search
    ? useGetTemplatesQuery
    : useGetTemplatesListQuery;

  const { data: templatesData, isFetching: isFetchingTemplates } =
    templatesRequest({
      search,
      page,
      limit: PAGINATION.limit,
    });
  const handleClickOpen = (e, { id, name, productsCount, img }) => {
    dispatch(
      showDialog({
        content: TEMPLATE_CONTENT,
        props: {
          id,
          img,
          description: name,
          productsCount,
          buttonProps: {
            component: "NavLink",
            to: `${pages.adCreate.path}?${TEMPLATE_ID}=${id}`,
          },
          onClick: () => dispatch(hideDialog()),
          onClose: () => dispatch(hideDialog()),
        },
      })
    );
  };

  const handleMoreData = () => {
    setPage((page) => page + 1);
  };

  return (
    <div className="flex flex-1 flex-col gap-4">
      <div className="flex flex-1 flex-col gap-4">
        {/*label SEARCH*/}
        <SearchInput
          placeholder="Search Templates"
          defaultValue={search}
          onChange={({ target }) => {
            debouncedSearch(() => {
              setSearch(target.value);
            });
          }}
        />
        {/*label TEMPLATES LIST*/}
        <List
          isSearch={!!search}
          isFetching={isFetchingTemplates}
          data={templatesData}
          next={handleMoreData}
          onCardClick={handleClickOpen}
        />
      </div>
    </div>
  );
};

export default Templates;
