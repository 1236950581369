import { useEffect, memo } from "react";
import { NavLink } from "react-router-dom";

import { useViewTemplateMutation } from "services/templates";

import Button from "ui/Button";
import Chip from "ui/Chip";

import Title from "components/Title";

import { getComponent } from "utils/components";

import { plural } from "utils";

const BUTTON_COMPONENT_MAP = {
  default: "",
  NavLink: NavLink,
};

const DialogTemplateContent = memo(
  ({
    id,
    img,
    description,
    productsCount,
    buttonProps = {},
    onClick = () => {},
    onClose = () => {},
  }) => {
    const { component, ...buttonPropsRest } = buttonProps;
    const buttonComponent = getComponent(BUTTON_COMPONENT_MAP, component);
    const [viewTemplate] = useViewTemplateMutation();

    const displayProductsCount = `${productsCount} ${plural(
      "product",
      productsCount
    )}`;

    useEffect(() => {
      viewTemplate(id);
    }, [id, viewTemplate]);

    return (
      <div className="flex flex-col flex-1 justify-between">
        <div className="flex-grow relative">
          <div className="absolute inset-0 flex items-center justify-center pt-12 pl-4 pr-4">
            <img
              src={img}
              alt={description}
              /*aspect-[4/5] */
              className="object-cover max-h-full max-w-full rounded-2xl"
            />
          </div>
        </div>
        <div className="flex flex-col gap-8 bg-base-0 p-6 flex-none">
          <div className="flex flex-col gap-4 items-center">
            <Chip label={displayProductsCount} color="secondary" size="small" />
            <Title size="2xl" className="px-10 text-center">
              {description}
            </Title>
          </div>

          <Button
            variant="contained"
            onClick={onClick}
            component={buttonComponent}
            {...buttonPropsRest}
          >
            Use this template
          </Button>
        </div>
      </div>
    );
  }
);

export default DialogTemplateContent;
