import CommonTemplate from "templates/CommonTemplate";
import AdsPage from "pages/Ads/pages/AdsList";

const Ads = ({
  onSelectChange = () => {},
  onSelectAdChange,
  buttonProps,
  headerProps,
}) => {
  return (
    <CommonTemplate headerProps={headerProps}>
      <AdsPage
        cardWithPlayer={false}
        buttonProps={buttonProps}
        onSelectAdChange={onSelectAdChange}
        // onSelectChange={onSelectChange}
        // onSelectAdChange={(a) => console.log({ a })}
      />
    </CommonTemplate>
  );
};

export default Ads;
