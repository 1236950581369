const InputLabel = ({ Icon, children }) => {
  return (
    <div className="flex gap-[5px] items-center text-xs">
      <Icon className="w-4 h-4" />
      {children}
    </div>
  );
};

export default InputLabel;
