import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";

import { hideDrawer } from "store/ducks/ui/drawer";

import Title from "components/Title";

import Button from "ui/Button";
import IconButton from "ui/IconButton";
import Checkbox from "ui/Checkbox";

import useFilters from "hooks/useFilter";

import { FILTER_TITLES, FILTERS } from "constants/filters";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

import colors from "themes/palette/colors.scss";

const Filter = ({ data }) => {
  const dispatch = useDispatch();

  const { filters } = data;

  const { control, setValue, reset, handleSubmit } = useForm();
  const { urlFilters, updateFilters, resetFilters } = useFilters(filters);

  useEffect(() => {
    Object.keys(urlFilters).forEach((key) => {
      setValue(key, urlFilters[key]);
    });
  }, [urlFilters, setValue]);

  const onSubmit = (data) => {
    updateFilters(data);
    dispatch(hideDrawer());
  };

  const handleCheckboxChange = (key, value, currentValues) => {
    if (currentValues.includes(value)) {
      setValue(
        key,
        currentValues.filter((v) => v !== value)
      );
    } else {
      setValue(key, [...currentValues, value]);
    }
  };

  const handleResetFilters = () => {
    resetFilters();
    reset({});
    dispatch(hideDrawer());
  };

  const handleCloseDrawer = () => dispatch(hideDrawer());

  return (
    <div className="flex flex-1 flex-col h-full overflow-hidden">
      {/*label HEADER*/}
      <div className="header container p-4 pt-8 mx-auto relative flex border-b border-base-100">
        {/*label PULLER*/}
        <span className="puller absolute w-10 h-[5px] left-1/2 top-[15px] transform -translate-x-1/2 bg-base-100 rounded-[5px]" />
        <div className="flex flex-1 gap-3 items-center justify-between">
          <Button variant="text" size="small" onClick={handleResetFilters}>
            Clear all
          </Button>
          <Title>Filter</Title>
          <IconButton aria-label="info" onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      {/*label CONTENT*/}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="content container p-4 mx-auto flex flex-1 flex-col gap-12 overflow-y-auto"
      >
        <div className="flex flex-1 flex-col gap-12 overflow-y-auto">
          {filters.map(({ key, values = [] }) => (
            <div key={key} className="fff flex flex-col gap-4">
              <Title size="2xl">{FILTER_TITLES?.[key] || key}</Title>
              <Controller
                name={key}
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <div className="flex gap-1 flex-wrap">
                    {values.map((val) => (
                      <FormControlLabel
                        key={val}
                        variant="checkbox"
                        control={
                          <Checkbox
                            variant="label"
                            checked={field.value.includes(val)}
                            onChange={() =>
                              handleCheckboxChange(key, val, field.value)
                            }
                          />
                        }
                        label={FILTERS?.[key]?.[val]?.label || val}
                      />
                    ))}
                  </div>
                )}
              />
            </div>
          ))}
        </div>
        <div className="flex gap-2 justify-between">
          <Button
            variant="contained"
            size="small"
            fullWidth
            onClick={() => dispatch(hideDrawer())}
            sx={{
              background: colors["base-50"],
              color: colors["base-1000"],
              "&:hover": {
                background: colors["base-50"],
                color: colors["base-1000"],
              },
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" size="small" fullWidth type="submit">
            Apply
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Filter;
