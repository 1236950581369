import MuiSkeleton from "@mui/material/Skeleton";
import { createBaseProxy } from "utils/components";

const Skeleton = createBaseProxy(MuiSkeleton, "Skeleton", {
  animation: "wave",
});

Skeleton.Rect = createBaseProxy(Skeleton, "SkeletonRectangular", {
  variant: "rectangular",
  animation: "wave",
});

Skeleton.Round = createBaseProxy(Skeleton, "SkeletonRounded", {
  variant: "rounded",
  animation: "wave",
});

Skeleton.Circ = createBaseProxy(Skeleton, "circularCircular", {
  variant: "circular",
  animation: "wave",
});

export default Skeleton;
