import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { hideDrawer } from "store/ducks/ui/drawer";

import pages from "router/links";

import Title from "components/Title";
import Button from "ui/Button";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as FolderIcon } from "assets/icons/folder.svg";

const ProductNotFound = ({ data: { changeView } }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddProduct = () => {
    dispatch(hideDrawer());
    navigate(pages.addNewProduct.path);
  };
  const handleCatalog = () => {
    dispatch(hideDrawer());
    changeView();
  };

  return (
    <div className="flex flex-1 flex-col justify-between gap-8">
      <div className="flex flex-1 flex-col gap-2 items-center justify-center px-8">
        <Title size="2xl">Product not found</Title>
        <span className="text-center text-sm text-base-500">
          Click 'Add Product' to submit a missing barcode or browse the catalog.
        </span>
      </div>
      <div className="flex gap-2">
        <Button
          startIcon={<PlusIcon className="w-4" />}
          className="sticky bottom-0 w-full"
          onClick={handleAddProduct}
        >
          Add Product
        </Button>
        <Button
          variant="contained"
          startIcon={<FolderIcon className="w-4" />}
          className="sticky bottom-0 w-full"
          onClick={handleCatalog}
        >
          Browse Catalog
        </Button>
      </div>
    </div>
  );
};

export default ProductNotFound;
