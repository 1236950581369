import Input from "ui/Input";
import InputAdornment from "ui/InputAdornment";

import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

const SearchInput = ({ placeholder, defaultValue, onChange }) => {
  return (
    <Input
      autoComplete="off"
      hiddenLabel
      placeholder={placeholder}
      defaultValue={defaultValue}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" className="text-base-500 ml-4">
            <SearchIcon className="w-[16px]" />
          </InputAdornment>
        ),
        classes: {
          root: "rounded-[1100px]",
        },
      }}
      onChange={onChange}
    />
  );
};

export default SearchInput;
