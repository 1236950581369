import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { useGetPlaylistsQuery } from "services/playlists";
import { useAssignToPlaylistMutation } from "services/ads";

import { hideDrawer } from "store/ducks/ui/drawer";

import SkeletonWrapper from "components/SkeletonWrapper";
import Playlist from "components/Playlist";

import Button from "ui/Button";

import pages from "router/links";

const AddToPlaylistForm = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: { data: playlistsData = [] } = {},
    isLoading: isLoadingGetPlaylists,
  } = useGetPlaylistsQuery({
    limit: 10,
  });
  const [assignToPlaylist, { isSuccess }] = useAssignToPlaylistMutation();

  const [loadingPlaylistIds, setLoadingPlaylistIds] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setLoadingPlaylistIds([]);
      dispatch(hideDrawer());
      navigate({
        pathname: pages.playlists.path,
      });
    }
  }, [dispatch, isSuccess, navigate]);

  const handleAssignToPlaylist = (id) => {
    setLoadingPlaylistIds((prev) => [...prev, id]);
    assignToPlaylist({
      ads: data.selectedAds,
      playlists: [id],
    }).finally(() => {
      setLoadingPlaylistIds((prev) =>
        prev.filter((playlistId) => playlistId !== id)
      );
    });
  };

  return (
    <div className="grid grid-cols-3 gap-4 pb-4">
      {(isLoadingGetPlaylists
        ? Array.from({ length: 3 }, () => ({ uuid: uuidv4() }))
        : playlistsData
      )
        .filter(({ isDefault }) => !isDefault)
        .map(({ uuid, id = uuid, name, previews }) => (
          <SkeletonWrapper
            key={id}
            condition={isLoadingGetPlaylists}
            Component={Playlist.Mini.Skeleton}
          >
            <Playlist.Mini
              id={id}
              key={id}
              name={name}
              videos={previews}
              component={Button}
              loading={loadingPlaylistIds.includes(id)}
              componentProps={{
                onClick: (_, { id }) => handleAssignToPlaylist(id),
              }}
            />
          </SkeletonWrapper>
        ))}
    </div>
  );
};

export default AddToPlaylistForm;
