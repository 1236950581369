import { api } from "services/api";

export const storesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStores: build.query({
      query: ({ limit } = {}) => ({
        url: "stores",
        params: limit ? { limit } : {},
      }),
    }),
    getStore: build.query({
      query: (id) => `stores/${id}`,
    }),
  }),
});

export const { useGetStoresQuery, useGetStoreQuery } = storesApi;
