import cx from "classnames";

import Button from "ui/Button";
import Skeleton from "ui/Skeleton";

import Title from "components/Title";
import ProductCard from "components/ProductCard";
import PlaylistCreateActions from "components/PlaylistCreateActions";
import SkeletonWrapper from "components/SkeletonWrapper";

import CommonTemplate from "templates/CommonTemplate";

import TemplateSelect from "pages/PlaylistCreate/components/TemplateSelect";
import Card from "pages/PlaylistCreate/Default/components/Card";
import { STEPS } from "pages/PlaylistCreate/Default/helpers";

const PRODUCT_LIMIT = 8;

const ListSelectedProducts = ({
  fields,
  register,
  errors,
  onDelete,
  onScan,
  onAdd,
  onChangeStep,
  headerProps,
  listSelectedProductsProps = {},
}) => {
  const countProducts = fields.length;
  const isDisabled = countProducts >= PRODUCT_LIMIT;

  const { template, isLoading: isTemplatesLoading } = listSelectedProductsProps;

  return (
    <CommonTemplate headerProps={headerProps}>
      <div className="flex flex-1 flex-col justify-between gap-2">
        <div className="flex flex-col gap-12">
          <SkeletonWrapper
            condition={isTemplatesLoading}
            skeletonProps={{ width: "100%" }}
            Component={Skeleton.Round}
          >
            <TemplateSelect
              id={template?.id}
              name={template?.name}
              img={template?.img}
              onChangeTemplate={() => onChangeStep(STEPS.TEMPLATES_CATALOG)}
            />
          </SkeletonWrapper>

          <div className="flex flex-col gap-8">
            <Title size="2xl">
              {countProducts}/{PRODUCT_LIMIT} videos{" "}
              <span className="text-base-300">(60s)</span>
            </Title>
            <div className="flex flex-col gap-4">
              {fields.map(({ id, img, name, brand, variant }, index) => (
                <Card
                  key={id}
                  index={index + 1}
                  onClick={() => onDelete(index)}
                >
                  <ProductCard
                    img={img}
                    name={name}
                    brand={brand}
                    variant={variant}
                    priceInputProps={{
                      ...register(`products.${index}.price`),
                      helperText: errors.products?.[index]?.price?.message,
                      error: !!errors.products?.[index]?.price,
                    }}
                    discountInputProps={{
                      ...register(`products.${index}.discount`),
                      helperText: errors.products?.[index]?.discount?.message,
                      error: !!errors.products?.[index]?.discount,
                    }}
                  />
                </Card>
              ))}
            </div>
            <PlaylistCreateActions
              disabled={isDisabled}
              onScan={onScan}
              onAdd={onAdd}
              className={cx({
                "min-h-[120px]": !countProducts,
              })}
            />
          </div>
        </div>
        <Button
          variant="contained"
          fullWidth
          classes={{ root: "sticky bottom-6" }}
          type="submit"
          disabled={!countProducts}
        >
          Save
        </Button>
      </div>
    </CommonTemplate>
  );
};

export default ListSelectedProducts;
