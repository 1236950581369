import cx from "classnames";
import { twMerge } from "tailwind-merge";

import IconButton from "ui/IconButton";
import Title from "components/Title";

import { renderComponent } from "utils/components";

import { ReactComponent as MoreIcon } from "assets/icons/more.svg";
import { ReactComponent as FolderIcon } from "assets/icons/folder.svg";

import styles from "./styles.module.scss";

const Img = ({ img, title, duration, className }) => (
  <>
    <img
      src={img}
      alt={title}
      className={twMerge(
        cx(
          "max-w-[120px] max-h-[140px] object-cover rounded-lg aspect-[16/9]",
          className
        )
      )}
    />
    <span className="absolute left-2 bottom-1.5 leading-none text-base-0 text-sm px-3 py-[5px] backdrop-blur-lg bg-base-0/[.2] rounded-3xl">
      {duration}
    </span>
  </>
);

const CardAction = ({ onClick }) => {
  return (
    <IconButton aria-label="info" className="p-0" onClick={onClick}>
      <MoreIcon />
    </IconButton>
  );
};

const SecondaryCard = ({
  img,
  title,
  description,
  folder,
  date,
  duration,
  className,
  isActive = false,
  ImgComponent = Img,
  ActionComponent = CardAction,
  imgComponentProps = {},
  actionComponentProps = {},
}) => {
  return (
    <div
      className={cx(
        "max-w-full flex flex-1 justify-between items-center gap-3 rounded-xl bg-base-50 p-1 pr-4",
        className,
        {
          [styles.active]: isActive,
        }
      )}
    >
      <div className="card-img w-auto relative">
        {renderComponent(ImgComponent, {
          img,
          title,
          duration,
          ...imgComponentProps,
        })}
      </div>
      <div className="card-content flex flex-col flex-grow gap-1 w-full max-w-screen-lg truncate">
        {title && <Title className="leading-none truncate">{title}</Title>}
        {description && (
          <Title className="leading-none text-base-500 text-sm truncate">
            {description}
          </Title>
        )}
        {(folder || date) && (
          <div className="flex items-center text-base-500">
            <div className="flex gap-1.5">
              <FolderIcon className="w-3.5" /> <span>{folder}</span>
            </div>
            {/*dot*/}
            <div className="mx-[5px] w-1 h-1 bg-base-500 rounded-full" />
            {/*todo replace with date util*/}
            <span>Feb 18</span>
          </div>
        )}
      </div>
      <div className="card-actions w-auto">
        {renderComponent(ActionComponent, actionComponentProps)}
      </div>
    </div>
  );
};

export default SecondaryCard;
