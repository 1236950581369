import {
  CONTACT_FORM,
  ADD_TO_PLAYLIST,
  AD_ACTIONS,
  AD_UPLOAD_FILE,
  PLAYLIST_ACTIONS,
  FILTER,
  PRODUCT_NOT_FOUND,
} from "constants/drawer";

import ContactForm from "modules/ContactForm";
import AddToPlaylistForm from "modules/AddToPlaylistForm";
import AdActions from "modules/AdActions";
import AdUploadFile from "modules/AdUploadFile";
import PlaylistActions from "modules/ActionsMenu";
import ProductNotFound from "modules/ProductNotFound";
import Filter from "modules/Filter";

export const CONTENT_MAP = {
  [CONTACT_FORM]: ContactForm,
  [ADD_TO_PLAYLIST]: AddToPlaylistForm,
  [AD_ACTIONS]: AdActions,
  [AD_UPLOAD_FILE]: AdUploadFile,
  [PLAYLIST_ACTIONS]: PlaylistActions,
  [PRODUCT_NOT_FOUND]: ProductNotFound,
  [FILTER]: Filter,
};
