import convertToYup from "json-schema-yup-transformer";

import Input from "ui/Input";
import IconButton from "ui/IconButton";
import InputAdornment from "ui/InputAdornment";

import Title from "components/Title";
import ProductCard from "components/ProductCard";

import {
  INPUT_FORM_NAME,
  MONO_PRODUCT_FIELD,
  MULTI_PRODUCT_FIELD,
} from "constants/ads";
import { PRODUCT_FIELDS } from "constants/product";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const getAdornment = (field) => {
  if ([PRODUCT_FIELDS.DISCOUNT_PERCENTAGE].includes(field)) {
    return "%";
  }
  if ([PRODUCT_FIELDS.OLD_PRICE, PRODUCT_FIELDS.PRICE].includes(field)) {
    return "$";
  }
  return null;
};

export const STEPS = {
  FORM: "FORM",
  PRODUCTS_FORM: "PRODUCTS_FORM",
  PRODUCTS_CATALOG: "PRODUCTS_CATALOG",
  VIDEO_PLAYER: "VIDEO_PLAYER",
  LOADER: "LOADER",
};

// label RENDER MONO FIELDS
export const renderMonoFields = (
  { properties, isProducts },
  { register, errors, setValue, getValues, trigger },
  ProductSelectActions,
  { onScan, onSelect }
) => {
  const selectedProduct = getValues(MONO_PRODUCT_FIELD);

  const handleDelete = () => {
    trigger(MONO_PRODUCT_FIELD);
    setValue(MONO_PRODUCT_FIELD, null);
  };

  return (
    <>
      {!isProducts &&
        (!!selectedProduct ? (
          <div className="relative flex max-h-[105px] ">
            <ProductCard
              name={selectedProduct?.displayName}
              brand={selectedProduct?.brand?.name}
              variant={selectedProduct?.variant}
              img={selectedProduct?.preview}
              Header={() => (
                <IconButton
                  aria-label="info"
                  className="absolute top-2 right-2"
                  onClick={handleDelete}
                >
                  <CloseIcon />
                </IconButton>
              )}
              Footer={null}
            />
          </div>
        ) : (
          <ProductSelectActions
            helperText={errors[MONO_PRODUCT_FIELD]?.message}
            error={!!errors[MONO_PRODUCT_FIELD]}
            onScan={onScan}
            onSelect={onSelect}
          />
        ))}

      {Object.entries(properties).map(([key, property]) => {
        // if the PRODUCT key does not render the field. This button which is rendered above
        if (key === MONO_PRODUCT_FIELD) {
          return null;
        }

        if (key !== MULTI_PRODUCT_FIELD) {
          return (
            <div key={key} className="flex flex-col">
              <Input.Label
                // todo
                {...register(key, {
                  setValueAs: (v) => (v === "" ? undefined : v),
                })}
                autoComplete="off"
                label={`Enter ${property?.meta?.label ?? key}`}
                multiline={!!property?.meta?.multiline}
                type={property?.meta?.type ?? "text"}
                helperText={errors[key]?.message}
                error={!!errors[key]}
                inputProps={{
                  inputMode: property?.meta?.inputMode,
                  step: property.meta?.step,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="text-base-1000 text-sm font-semibold">
                        {getAdornment(key)}
                      </span>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

// label RENDER MULTI FIELDS
export const renderMultiFields = (
  { properties, definitions },
  { register, errors, setValue, getValues, resetField, trigger },
  ProductSelectActions,
  { onScan, onSelect }
) =>
  Object.entries(properties).map(([propertyKey, property]) => {
    if (propertyKey === MULTI_PRODUCT_FIELD) {
      return (
        <div key={propertyKey}>
          {Object.entries(property.properties).map(
            ([productKey, { $ref }], index) => {
              const path = $ref.replace(/^#\/definitions\//, "");
              const { properties } = definitions[path];
              const selectedProduct = getValues(
                `${MULTI_PRODUCT_FIELD}.${productKey}.${MONO_PRODUCT_FIELD}`
              );

              const handleDelete = () => {
                trigger(
                  `${MULTI_PRODUCT_FIELD}.${productKey}.${MONO_PRODUCT_FIELD}`
                );
                setValue(
                  `${MULTI_PRODUCT_FIELD}.${productKey}.${MONO_PRODUCT_FIELD}`,
                  null
                );
              };

              return (
                <div
                  key={productKey}
                  className="flex flex-col gap-4 py-6 border-b last:border-b-0 border-base-100"
                >
                  <Title size="2xl">Product {index + 1}</Title>
                  {!!selectedProduct ? (
                    <div className="relative flex max-h-[105px] ">
                      <ProductCard
                        name={selectedProduct?.displayName}
                        brand={selectedProduct?.brand?.name}
                        variant={selectedProduct?.variant}
                        img={selectedProduct?.preview}
                        Header={() => (
                          <IconButton
                            aria-label="info"
                            className="absolute top-2 right-2"
                            onClick={handleDelete}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                        Footer={null}
                      />
                    </div>
                  ) : (
                    <ProductSelectActions
                      helperText={
                        errors[MULTI_PRODUCT_FIELD]?.[productKey]?.message
                      }
                      error={!!errors[MULTI_PRODUCT_FIELD]?.[productKey]}
                      onScan={(e) => onScan(e, productKey)}
                      onSelect={(e) => onSelect(e, productKey)}
                    />
                  )}

                  {Object.entries(properties).map(([key, { meta }]) => (
                    <Input.Label
                      key={key}
                      // todo
                      {...register(`${propertyKey}.${productKey}.${key}`, {
                        setValueAs: (v) => (v === "" ? undefined : v),
                      })}
                      autoComplete="off"
                      label={`Enter ${meta.label ?? key}`}
                      placeholder={meta.placeholder}
                      type={meta.type ?? "text"}
                      helperText={
                        errors?.products?.[productKey]?.[key]?.message
                      }
                      error={!!errors?.products?.[productKey]?.[key]}
                      inputProps={{
                        inputMode: meta?.inputMode,
                        step: meta?.step,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span className="text-base-1000 text-sm font-semibold">
                              {getAdornment(key)}
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ))}
                </div>
              );
            }
          )}
        </div>
      );
    }
    return null;
  });

export default renderMonoFields;

export const FIELDS_PROPS = {
  [PRODUCT_FIELDS.PRICE]: {
    type: "number",
    minimum: 0.01,
    meta: {
      type: "number",
      label: "Price",
      placeholder: "Price",
      step: "0.01",
      inputMode: "decimal",
    },
  },
  [PRODUCT_FIELDS.OLD_PRICE]: {
    type: "number",
    minimum: 0.01,
    meta: {
      type: "number",
      label: "Old Price",
      placeholder: "Old Price",
      step: "0.01",
      inputMode: "decimal",
    },
  },
  [PRODUCT_FIELDS.DISCOUNT]: {
    type: "number",
    minimum: 0.01,
    meta: {
      type: "number",
      label: "saving amount",
      step: "0.01",
      inputMode: "decimal",
    },
  },
  [PRODUCT_FIELDS.DISCOUNT_PERCENTAGE]: {
    type: "number",
    minimum: 1,
    maximum: 99,
    meta: {
      type: "number",
      label: "Discount Percentage",
      inputMode: "numeric",
    },
  },
};

const mergeDeep = (defaultProps, newProps) => {
  const merged = { ...defaultProps };

  Object.keys(newProps).forEach((key) => {
    if (typeof newProps[key] === "object" && newProps[key] !== null) {
      merged[key] = mergeDeep(defaultProps[key] || {}, newProps[key]);
    } else {
      merged[key] = newProps[key];
    }
  });

  return merged;
};

const mergeDefaultFieldValues = (inputObject) => {
  const result = {};

  Object.keys(inputObject).forEach((key) => {
    const defaultProps = FIELDS_PROPS[key];

    if (defaultProps) {
      result[key] = mergeDeep(defaultProps, inputObject[key]);
    } else if (inputObject[key].properties) {
      // If there is a properties property, call mergeDefaults for it
      result[key] = {
        ...inputObject[key],
        properties: mergeDefaultFieldValues(inputObject[key].properties),
      };
    } else {
      result[key] = inputObject[key];
    }
  });

  return result;
};

const formatFieldName = (field) => {
  if (Array.isArray(field)) {
    const fieldName = field[0];
    return formatFieldName(fieldName);
  }

  if (typeof field === "object" && field !== null) {
    const requiredFields = field.required
      .split(",")
      .map((name) => formatFieldName(name.trim()));
    return requiredFields.join(", ");
  }

  if (typeof field === "string") {
    return field
      .replace(/([A-Z])/g, " $1")
      .trim()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return String(field);
};

const defaultErrors = {
  number: (field) => `${formatFieldName(field)} is not a number`,
  required: (field) => `${formatFieldName(field)} is required.`,
  minimum: ([field, { minimum }]) =>
    `${formatFieldName(field)} requires a minimum value of ${minimum}`,
  maximum: ([field, { maximum }]) =>
    `${formatFieldName(field)} cannot exceed a maximum value of ${maximum}`,
};

const generateConfig = (customErrors) => {
  const config = {
    errors: {
      defaults: defaultErrors,
    },
  };

  Object.keys(customErrors).forEach((key) => {
    config.errors[key] = customErrors[key];
  });

  return config;
};

export const generateSchema = (data) => {
  const originalConfig = data;
  if (!originalConfig) {
    throw Error("Config incorrect or not found");
  }
  const { schema: originalSchema = {}, config = {} } = originalConfig;
  const { properties } = originalSchema;
  const isProducts = !!properties?.products;

  const definitions = originalSchema.definitions
    ? { definitions: mergeDefaultFieldValues(originalSchema.definitions) }
    : {};
  const productField = isProducts
    ? {}
    : {
        [MONO_PRODUCT_FIELD]: {
          type: "object",
        },
      };

  const schema = {
    ...originalSchema,
    ...definitions,

    properties: {
      ...mergeDefaultFieldValues(originalSchema.properties),
      ...productField,
    },
    required: [
      INPUT_FORM_NAME,
      ...(isProducts
        ? Object.keys(properties.products?.properties || {})
        : [MONO_PRODUCT_FIELD]),
      ...(originalSchema?.required || []),
    ],
  };

  const jsonSchemaConfig = generateConfig(config);
  const yupSchema = convertToYup(schema, jsonSchemaConfig);

  return { originalSchema, schema, yupSchema, isProducts };
};
