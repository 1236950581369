import List from "ui/List";
import ListItem from "ui/ListItem";
import ListItemButton from "ui/ListItemButton";
import ListItemIcon from "ui/ListItemIcon";
import ListItemText from "ui/ListItemText";

import Title from "components/Title";

import { formatMilliseconds } from "utils/date";

const AdActions = ({ data = {} }) => {
  const { menuItems, ad = {} } = data;
  const { id, ids, name, img, duration } = ad;

  return (
    <div className="flex flex-col h-full overflow-hidden">
      {/*label HEADER*/}
      <div className="header container p-4 pt-8 mx-auto relative flex border-b border-base-100">
        {/*label PULLER*/}
        <span className="puller absolute w-10 h-[5px] left-1/2 top-[15px] transform -translate-x-1/2 bg-base-100 rounded-[5px]" />
        <div className="flex gap-3 items-center">
          <img
            src={img}
            alt={name}
            className="w-10 h-10 object-cover rounded-lg"
          />
          <div className="flex flex-col gap-1">
            <Title>{name}</Title>
            <span className="text-base-500 leading-none">
              {formatMilliseconds(duration)}
            </span>
          </div>
        </div>
      </div>
      {/*label CONTENT*/}
      <div className="content container p-4 mx-auto flex-1 overflow-y-auto">
        <List>
          {menuItems.map(({ icon: Icon, label, color, disabled, onClick }) => (
            <ListItem key={label}>
              <ListItemButton
                className={color}
                disabled={disabled}
                onClick={(e) => onClick(e, { id, ids })}
              >
                <ListItemIcon>
                  <Icon className="h-5" />
                </ListItemIcon>
                <ListItemText>{label}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

export default AdActions;
