import { renderComponent } from "utils/components";

import EmptyData from "components/EmptyData";

const defaultProps = {
  data: [],
  alt: EmptyData,
};

const ComponentFallbackRenderer = ({
  data,
  isLoading = false,
  children,
  alt,
}) => {
  if (!data.length && !isLoading) {
    return renderComponent(alt, children);
  }

  return children(data);
};

ComponentFallbackRenderer.defaultProps = defaultProps;

export default ComponentFallbackRenderer;
