import Title from "components/Title";
import IconButton from "ui/IconButton";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const Card = ({ children, onClick, index }) => (
  <div className="flex flex-col">
    <div className="flex justify-between items-center">
      <Title>Product {index}</Title>
      <IconButton aria-label="info" onClick={onClick}>
        <CloseIcon />
      </IconButton>
    </div>
    {children}
  </div>
);

export default Card;
