import Skeleton from "ui/Skeleton";

const SkeletonWrapper = ({
  children,
  condition = false,
  skeletonProps = {},
  Component = Skeleton,
}) => {
  if (condition) {
    return <Component {...skeletonProps}>{children}</Component>;
  }

  return children;
};

export default SkeletonWrapper;
