import React from "react";
import "./App.scss";

import store from "store/store";
import AbsoluteComponents from "templates/AbsoluteComponents";
import ScrollToTop from "components/ScrollToTop";

import AuthProvider from "providers/AuthProvider";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";

import { BrowserRouter, Routes } from "react-router-dom";
import routes from "router";

import { API_URL } from "constants/APIs";
import { theme } from "themes";

function App() {
  return (
    <Provider store={store}>
      <AuthProvider apiUrl={API_URL}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ScrollToTop />
            <AbsoluteComponents />
            <Routes>{routes}</Routes>
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </Provider>
  );
}

export default App;
