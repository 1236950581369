import MediaCard from "components/MediaCard";

import Button from "ui/Button";

import { useAssignPlaylistMutation } from "services/playlists";

// todo replace
import PlaceholderImg from "assets/mockImgaes/Rectangle 4144.png";

const Card = ({ id, name, title, playlistId }) => {
  const [
    assignPlaylist,
    { data: { devices = [] } = {}, isLoading: isLoadingAssignPlaylist },
  ] = useAssignPlaylistMutation();

  const handleAssign = (playlistId, id) => {
    assignPlaylist({ playlistId, deviceId: id });
  };

  const isConnected = devices?.includes(id);
  const buttonColor = isConnected ? "secondary" : "primary";
  const buttonTextColor = isConnected ? "text-base-1000" : "inherit";
  const buttonText = isConnected ? "Disconnect" : "Add to device";

  return (
    <MediaCard.Secondary
      img={PlaceholderImg}
      title={title}
      description={name}
      ImgComponent={ImgComponent}
      ActionComponent={
        <div className="flex justify-end min-w-40">
          <Button.Loading
            size="small"
            variant="contained"
            color={buttonColor}
            loading={isLoadingAssignPlaylist}
            sx={{
              padding: "9px 20px !important",
              borderRadius: 12,
            }}
            onClick={() => handleAssign(playlistId, id)}
          >
            <span className={buttonTextColor}>{buttonText}</span>
          </Button.Loading>
        </div>
      }
    />
  );
};

function ImgComponent({ img, title }) {
  return (
    <img
      src={img}
      alt={title}
      className="w-[72px] max-w-none object-cover rounded-lg aspect-auto"
    />
  );
}

export default Card;
