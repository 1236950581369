import { ReactComponent as PlaylistIcon } from "assets/icons/Playlists.svg";
import { ReactComponent as TemplatesIcon } from "assets/icons/Templates.svg";
import { ReactComponent as AdsIcon } from "assets/icons/Ads.svg";
import { ReactComponent as StoresIcon } from "assets/icons/Stores.svg";

export const NAVIGATION_MAP = {
  playlists: {
    icon: <PlaylistIcon />,
  },
  templates: {
    icon: <TemplatesIcon />,
  },
  ads: {
    icon: <AdsIcon />,
  },
  stores: {
    icon: <StoresIcon />,
  },
};
