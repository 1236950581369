// todo TECH DEBT! ---- Rewrite Slider Component
import { useRef, useEffect } from "react";
import cx from "classnames";

import Swiper from "swiper/bundle";
import { Navigation, Pagination } from "swiper/modules";

import "swiper/css/bundle";
import styles from "./styles.module.scss";

const Slider = ({
  sliderProps: {
    autoplay = false,
    loop = false,
    navigation = false,
    pagination = false,
    slidesPerView = 1,
    slidesOffsetAfter = 0,
    spaceBetween = 0,
  } = {},
  className,
  slides = [],
  children,
  ...props
}) => {
  const sliderRef = useRef();

  useEffect(() => {
    let swiper = null;

    if (sliderRef.current) {
      swiper = new Swiper(sliderRef.current, {
        modules: [Navigation, Pagination],
        autoplay,
        loop,
        slidesPerView,
        slidesOffsetAfter,
        spaceBetween,
        pagination,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
      swiper.init();
    }
    return () => {
      if (swiper) {
        swiper.destroy();
      }
    };
  }, [
    pagination,
    slidesPerView,
    slidesOffsetAfter,
    spaceBetween,
    loop,
    autoplay,
  ]);

  return (
    <div className={cx(styles.globalWrapper, className)}>
      <div className={styles.wrapper}>
        <div className="swiper" ref={sliderRef}>
          <div className="swiper-wrapper">
            {slides.map((slide, index) => {
              return (
                <div key={index} className="swiper-slide">
                  {children(slide)}
                </div>
              );
            })}
          </div>
          {!!pagination && (
            <div className={cx("swiper-pagination", styles.pagination)} />
          )}

          {/*<div className="swiper-button-prev" />*/}
          {/*<div className="swiper-button-next" />*/}

          {/*<div className="swiper-scrollbar" />*/}
        </div>
      </div>
    </div>
  );
};

export default Slider;
