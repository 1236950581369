import { Navigate, Outlet } from "react-router-dom";
import useAuth from "hooks/useAuth";

import pages from "router/links";

const PrivateRoute = ({ status }) => {
  const { isLogged } = useAuth();

  return isLogged ? <Outlet /> : <Navigate to={pages.login.path} />;
};

export default PrivateRoute;
