import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  content: "",
  anchor: "bottom",
  data: {},
};

const drawerSlice = createSlice({
  name: "[ui] drawer",
  initialState,
  reducers: {
    show(state, action) {
      state.isOpen = true;
      state.content = action.payload?.content || "";
      state.data = action.payload?.data;
      state.headerProps = action.payload?.headerProps;
      state.drawerProps = action.payload?.drawerProps;
      state.base = action.payload?.base;
    },
    hide(state) {
      return { ...initialState, anchor: state.anchor };
    },
  },
});

export const { show: showDrawer, hide: hideDrawer } = drawerSlice.actions;
export const selectUiDrawerState = (state) => state.ui.drawer;

export default drawerSlice.reducer;
