import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  content: "",
  data: {},
  props: {},
};

const dialogSlice = createSlice({
  name: "[ui] dialog",
  initialState,
  reducers: {
    show(state, action) {
      state.isOpen = true;
      state.content = action.payload?.content || "";
      state.data = action.payload?.data;
      state.props = action.payload?.props;
    },
    hide() {
      return initialState;
    },
  },
});

export const { show: showDialog, hide: hideDialog } = dialogSlice.actions;
export const selectUiDialogState = (state) => state.ui.dialog;

export default dialogSlice.reducer;
