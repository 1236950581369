import cx from "classnames";

import IconButton from "ui/IconButton";
import Avatar from "ui/Avatar";

import { NavLink } from "react-router-dom";

import { ReactComponent as QuestionIcon } from "assets/icons/question.svg";

import pages from "router/links";
import styles from "./styles.module.scss";

const Header = ({ className, name, email, onActionClick = () => {} }) => {
  const shortName = name?.slice(0, 1).toUpperCase();

  return (
    <header className={cx(styles.root, className)}>
      <div className="container mx-auto p-4 flex flex-row justify-between items-center">
        <div className="flex gap-x-3 items-center">
          <Avatar component={NavLink} to={pages.profile.path}>
            {shortName}
          </Avatar>
          <div className="flex flex-col gap-y-[4px]">
            <span className="text-base text-base-1000 font-medium leading-4">
              {name}
            </span>
            <span className="text-sm text-base-500 leading-none">{email}</span>
          </div>
        </div>
        <div>
          <IconButton aria-label="info" className="p-0" onClick={onActionClick}>
            <QuestionIcon />
          </IconButton>
        </div>
      </div>
    </header>
  );
};

export default Header;
