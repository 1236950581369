import cx from "classnames";
import { twMerge } from "tailwind-merge";

import SwipeableDrawer from "ui/SwipeableDrawer";

const Header = ({ title }) =>
  title && <span className="font-medium">{title}</span>;

const BaseSwipeableDrawer = ({
  anchor,
  open,
  onClose = () => {},
  onOpen = () => {},
  header: HeaderComponent = Header,
  headerProps = {},
  drawerProps = {},
  base = false,
  children,
}) => {
  const { title, hideBorder = false, ...restHeaderProps } = headerProps;
  const { PaperProps = {}, ...restDrawerProps } = drawerProps;
  const { className: paperClassName, ...restPaperProps } = PaperProps;

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{
        classes: {
          root: twMerge(
            cx("max-h-[90%] min-h-[10%] rounded-t-[35px]", paperClassName)
          ),
        },
        ...restPaperProps,
      }}
      ModalProps={{
        keepMounted: false,
      }}
      disableSwipeToOpen
      {...restDrawerProps}
    >
      {base ? (
        children
      ) : (
        <div className="flex flex-1 flex-col h-full overflow-hidden">
          {/*label HEADER*/}
          <div
            className={cx(
              "header container p-4 pt-8 mx-auto relative flex justify-center border-base-100",
              {
                "border-b": !hideBorder,
              }
            )}
          >
            {/*label PULLER*/}
            <span className="puller absolute w-10 h-[5px] left-1/2 top-[15px] transform -translate-x-1/2 bg-base-100 rounded-[5px]" />
            <HeaderComponent title={title} {...restHeaderProps} />
          </div>
          {/*label CONTENT*/}
          <div className="flex flex-col flex-1 content container p-4 mx-auto overflow-y-auto">
            {children}
          </div>
        </div>
      )}
    </SwipeableDrawer>
  );
};

export default BaseSwipeableDrawer;
