import CommonTemplate from "templates/CommonTemplate";

import Button from "ui/Button";
import Input from "ui/Input";
import Skeleton from "ui/Skeleton";

const Form = ({
  isLoading,
  headerProps,
  register,
  errors,
  fieldName = "name",
  submitButtonLabel = "Next",
  submitButtonProps = {},
  onClick,
}) => {
  return (
    <CommonTemplate headerProps={headerProps}>
      <div className="flex flex-1 flex-col justify-between gap-2">
        <div className="flex flex-col gap-12">
          {isLoading ? (
            <Skeleton.Round width="100%">
              <Input />
            </Skeleton.Round>
          ) : (
            <Input
              {...register(fieldName)}
              autoComplete="off"
              label="Name"
              type="text"
              helperText={errors[fieldName]?.message}
              error={!!errors[fieldName]}
            />
          )}
        </div>
        <Button.Loading
          variant="contained"
          fullWidth
          classes={{ root: "sticky bottom-6" }}
          onClick={onClick}
          {...submitButtonProps}
        >
          {submitButtonLabel}
        </Button.Loading>
      </div>
    </CommonTemplate>
  );
};

export default Form;
