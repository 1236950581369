import cx from "classnames";

import Button from "ui/Button";
import IconButton from "ui/IconButton";
import Chip from "ui/Chip";

import { VIEWS } from "constants/index";

import { ReactComponent as ListIcon } from "assets/icons/list.svg";
import { ReactComponent as CardIcon } from "assets/icons/card.svg";

const Actions = ({
  view,
  onToggle,
  onSelect,
  isEditMode,
  isShowSelect,
  countOfSelected,
}) => {
  if (isEditMode) {
    return (
      <div className="flex gap-4">
        {isShowSelect && (
          <Button variant="text" size="small" onClick={onSelect}>
            Unselect
          </Button>
        )}
        <Chip
          label={`${countOfSelected} selected`}
          color="secondary"
          size="small"
        />
      </div>
    );
  }

  return (
    <div className="flex gap-6">
      {isShowSelect && (
        <Button variant="text" size="small" onClick={onSelect}>
          Select
        </Button>
      )}
      <div className="flex gap-4">
        <IconButton
          aria-label="list"
          className={cx("p-0 text-base-300", {
            "text-brand-500": view === VIEWS.LIST,
          })}
          onClick={() => onToggle(VIEWS.LIST)}
        >
          <ListIcon />
        </IconButton>
        <IconButton
          aria-label="card"
          className={cx("p-0 text-base-300", {
            "text-brand-500": view === VIEWS.CARD,
          })}
          onClick={() => onToggle(VIEWS.CARD)}
        >
          <CardIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default Actions;
