export const barcodesMap = {
  // icecream =The Macallan Double Cask 12 Year Aged
  4820185230988: "812066021598",
  // dirol = Apothic Red Blend
  7622202022845: "85000017746",
  // colgate = Tito's Handmade Vodka
  8850006931509: "619947000020",
  // oxford = Svedka Vodka
  5030009351440: "617768111178",
  8437004141019: "812066021598",
  8000570435402: "85000017746",
  4820034921500: "617768111178",

  // Kate 1
  4820046963369: "617768111178",
  8001651338087: "812066021598",
  5000267014401: "85000017746",
  8001592005680: "617768111178",

  // Kate 2
  8005415058785: "617768111178",
  5099873089798: "85000017746",
};
