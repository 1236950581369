import Button from "ui/Button";

import { PRODUCT_VIEWS } from "constants/index";

import { ReactComponent as ScanIcon } from "assets/icons/scan.svg";
import { ReactComponent as FolderIcon } from "assets/icons/folder.svg";

const SwitchView = ({ value, onClick }) => {
  return (
    <div className="flex bg-base-0/[.8] p-2 backdrop-blur-[25px] border border-base-100 rounded-[20px] w-full mx-2.5">
      <Button
        fullWidth
        variant={value === PRODUCT_VIEWS.SCANNER ? "contained" : "text"}
        color={value === PRODUCT_VIEWS.SCANNER ? "primary" : "inherit"}
        startIcon={<ScanIcon className="w-4 h-4" />}
        onClick={() => onClick(PRODUCT_VIEWS.SCANNER)}
      >
        Scan Barcode
      </Button>
      <Button
        fullWidth
        variant={value === PRODUCT_VIEWS.CATALOG ? "contained" : "text"}
        color={value === PRODUCT_VIEWS.CATALOG ? "primary" : "inherit"}
        startIcon={<FolderIcon className="w-4" />}
        onClick={() => onClick(PRODUCT_VIEWS.CATALOG)}
      >
        Browse Catalog
      </Button>
    </div>
  );
};

export default SwitchView;
