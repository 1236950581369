import { renderComponent } from "utils/components";

import { ReactComponent as NounPackagingIcon } from "assets/icons/noun-packaging.svg";
import Title from "../Title";

const TitleComp = (title) => <Title size="2xl">{title}</Title>;
const Description = (description) => (
  <span className="text-base-500">{description}</span>
);

const EmptyData = ({
  Icon = NounPackagingIcon,
  title = "No results to show",
  description = "You can checkout this section later",
  TitleComponent = TitleComp,
  DescriptionComponent = Description,
}) => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center text-center gap-4">
      <Icon />
      {renderComponent(TitleComponent(title))}
      {renderComponent(DescriptionComponent(description))}
    </div>
  );
};

export default EmptyData;
