import { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useFilters = (filters) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [urlFilters, setUrlFilters] = useState({});
  const [filterCount, setFilterCount] = useState(0);

  // Helper function to convert URL parameter values to the appropriate type
  const convertValue = (value, originalValues) => {
    return originalValues.includes(Number(value)) ? Number(value) : value;
  };

  // Parse URL parameters and set default checkbox values
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newFilters = {};
    let count = 0;

    filters.forEach(({ key, values }) => {
      const paramValue = searchParams.get(`filter[${key}]`);
      if (paramValue) {
        const paramValues = paramValue
          .split(",")
          .map((value) => convertValue(value, values));
        newFilters[key] = paramValues;
        count += 1;
      }
    });

    setUrlFilters(newFilters);
    setFilterCount(count);
  }, [location.search, filters]);

  // Update URL with selected filters
  const updateFilters = useCallback(
    (data) => {
      const newParams = [];

      Object.keys(data).forEach((key) => {
        if (data[key]?.length) {
          // Without encoding values
          const values = data[key].join(",");
          newParams.push(`filter[${key}]=${values}`);
        }
      });

      // Join parameters into a single query string
      const newQueryString = newParams.join("&");

      // Construct the new URL
      const newUrl = newQueryString
        ? `${window.location.pathname}?${newQueryString}`
        : window.location.pathname;

      // Update the URL without reloading the page using React Router's navigate
      navigate(newUrl, { replace: true });
    },
    [navigate]
  );

  // Reset filters and update URL
  const resetFilters = useCallback(() => {
    // Clear the URL filters
    setUrlFilters({});
    setFilterCount(0);

    // Update URL to remove all filter parameters
    navigate(window.location.pathname, { replace: true });
  }, [navigate]);

  // Check if any filters are active
  const isActiveFilter = Object.keys(urlFilters).length > 0;

  return {
    urlFilters,
    updateFilters,
    resetFilters,
    filterCount,
    isActiveFilter,
  };
};

export default useFilters;
