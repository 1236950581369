export const COLORS = {
  primary: {
    icon: "border-base-300",
    checkedIcon: "bg-brand-500 after:border-brand-500 text-base-0",
  },

  secondary: {
    icon: "border-base-0",
    checkedIcon: "bg-base-0 after:border-base-0 text-base-1000",
  },
};
