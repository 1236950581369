import { DotLottieReact } from "@lottiefiles/dotlottie-react";

import animation from "assets/animations/wine.lottie";

const Loader = () => {
  return (
    <div className="min-h-svh flex flex-col flex-1 items-center justify-center p-11 relative">
      <div>
        <DotLottieReact src={animation} loop autoplay />
      </div>
      <div className="flex flex-col gap-2.5 items-center absolute bottom-11">
        <span>Wait, there's magic going on here</span>
        <span className="text-sm text-base-500">
          Your video is on its way! Please wait a moment.
        </span>
      </div>
    </div>
  );
};

export default Loader;
