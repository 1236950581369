import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { hideDrawer } from "store/ducks/ui/drawer";

import {
  useCreateAdFromImageMutation,
  useApproveAdMutation,
} from "services/ads";

import BaseTemplate from "templates/BaseTemplate";

import { FILE_NAME } from "constants/ads";

import { getComponent } from "utils/components";

import Skeleton from "ui/Skeleton";
import Loader from "components/Loader";

import AdsList from "./pages/AdsList";
import VideoPlayer from "pages/VideoPlayer";

import { STEPS } from "./helpers";

// todo
const AdsPage = (props) => (
  <BaseTemplate>
    <AdsList {...props} />
  </BaseTemplate>
);

const STEPS_MAP = {
  default: AdsPage,
  [STEPS.LIST]: AdsPage,
  [STEPS.VIDEO_PLAYER]: VideoPlayer,
  [STEPS.LOADER]: Loader,
};

const Ads = () => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(STEPS.LIST);
  const Component = getComponent(STEPS_MAP, step);

  // label CREATE AD FROM IMAGE
  const [createAdFromImage, { data: createAdFromImageData }] =
    useCreateAdFromImageMutation();

  // label APPROVE PLAYLIST
  const [approveAd] = useApproveAdMutation();

  // label COMPONENT PROPS
  const getComponentProps = useCallback(
    (step) => {
      let props = {};
      switch (step) {
        default:
        case STEPS.LIST:
          props = {
            onSubmitFile: (data) => {
              const formData = new FormData();
              formData.append(FILE_NAME, data?.[FILE_NAME]);
              formData.append("seconds", data.seconds);

              setStep(STEPS.LOADER);
              dispatch(hideDrawer());
              createAdFromImage(formData)
                .unwrap()
                .then(() => setStep(STEPS.VIDEO_PLAYER))
                .catch(() => setStep(STEPS.LIST));
            },
            showCreateAdButton: true,
          };
          break;
        case STEPS.LOADER:
          props = {};
          break;
        case STEPS.VIDEO_PLAYER:
          props = {
            headerProps: {
              title: "New Ad",
              onBack: () => setStep(STEPS.LIST),
            },
            videoUrl: createAdFromImageData?.video_url,
            poster: createAdFromImageData?.min_preview_url,
            onSubmit: () =>
              approveAd(createAdFromImageData?.id)
                .unwrap()
                .then(() => {
                  setStep(STEPS.LIST);
                }),
          };
          break;
      }

      return props;
    },
    [
      approveAd,
      createAdFromImage,
      createAdFromImageData?.id,
      createAdFromImageData?.min_preview_url,
      createAdFromImageData?.video_url,
      dispatch,
    ]
  );

  return <Component {...getComponentProps(step)} />;
};

Ads.CardSkeleton = () => (
  <div className="flex flex-col gap-3 ">
    <Skeleton.Round
      sx={{
        aspectRatio: "9 / 16",
        width: "100%",
        height: "auto",
      }}
    />
    <Skeleton width="60%" sx={{ fontSize: "1rem" }} />
  </div>
);

export default Ads;
