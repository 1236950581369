import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import BaseSwipeableDrawer from "components/BaseSwipeableDrawer";
import { createComponentsMap } from "utils/components";

import { selectUiDrawerState, hideDrawer } from "store/ducks/ui/drawer";

import { CONTENT_MAP } from "./helpers";

const getContentComponent = createComponentsMap(CONTENT_MAP);

const Drawer = () => {
  const dispatch = useDispatch();

  const {
    isOpen,
    anchor,
    content,
    data = {},
    headerProps = {},
    drawerProps = {},
    base = false,
  } = useSelector(selectUiDrawerState);

  const [Content] = useMemo(() => getContentComponent(content), [content]);

  const handleModalClose = () => dispatch(hideDrawer());

  return (
    <BaseSwipeableDrawer
      anchor={anchor}
      open={isOpen}
      onClose={handleModalClose}
      headerProps={headerProps}
      drawerProps={drawerProps}
      base={base}
    >
      <Content data={data} />
    </BaseSwipeableDrawer>
  );
};

export default Drawer;
