import { useParams } from "react-router-dom";

import DefaultPlaylist from "./Default";
import NewPlaylist from "./New";

import { getComponent } from "utils/components";

const ComponentsMap = {
  default: DefaultPlaylist,
  new: NewPlaylist,
};

const PlaylistCreate = ({ isDefault = false }) => {
  const { type } = useParams();
  const Component = getComponent(ComponentsMap, type);

  return <Component />;
};

export default PlaylistCreate;
