import Input from "ui/Input";
import InputAdornment from "ui/InputAdornment";

import InputLabel from "components/ProductCard/components/InputLabel";

const Footer = ({
  priceInputProps,
  discountInputProps,
  PriceIcon,
  DiscountIcon,
}) => {
  return (
    <>
      <div className="flex gap-4">
        <Input
          {...priceInputProps}
          variant="standard"
          autoComplete="off"
          type="text"
          label={<InputLabel Icon={PriceIcon}>Price</InputLabel>}
          placeholder="100"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            classes: { root: "mt-[6px]" },
          }}
          inputProps={{
            inputMode: "decimal",
          }}
        />
        <Input
          {...discountInputProps}
          variant="standard"
          autoComplete="off"
          type="text"
          label={<InputLabel Icon={DiscountIcon}>Discount</InputLabel>}
          placeholder="20"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            classes: { root: "mt-[6px]" },
          }}
          inputProps={{
            inputMode: "decimal",
          }}
        />
      </div>
    </>
  );
};

export default Footer;
