import Slider from "components/Slider";

const LinearSwiper = ({ slides, sliderProps, children, ...props }) => {
  const { slidesPerView = 3, ...sliderPropsRest } = sliderProps;

  return (
    <Slider
      slides={slides}
      sliderProps={{
        slidesPerView: slidesPerView + 0.2,
        spaceBetween: 8,
        ...sliderPropsRest,
      }}
      {...props}
    >
      {(props) => {
        return children ? children(props) : null;
      }}
    </Slider>
  );
};

export default LinearSwiper;
