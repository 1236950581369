import { CATEGORIES } from "constants/categories";

export const FILTERS = {
  categories: CATEGORIES,
};

export const FILTER_TITLES = {
  categories: "Category",
  products_count: "Number of bottles",
};
