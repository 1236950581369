import { useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { replaceParams } from "utils/router";

import CommonTemplate from "templates/CommonTemplate";

import Section from "components/Section";
import Playlist from "components/Playlist";

import Button from "ui/Button";
import Switch from "ui/Switch";
import IconButton from "ui/IconButton";

import {
  useChangeStatusPlaylistMutation,
  useDeletePlaylistMutation,
  useGetPlaylistsQuery,
} from "services/playlists";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "assets/icons/more.svg";
import { ReactComponent as RenameIcon } from "assets/icons/rename.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

import pages from "router/links";
import { hideDrawer, showDrawer } from "../../store/ducks/ui/drawer";
import { PLAYLIST_ACTIONS } from "../../constants/drawer";
import { useDispatch } from "react-redux";

const Screen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { screenId } = useParams();

  const [changeStatus, { data, isLoading: isLoadingChangeStatus }] =
    useChangeStatusPlaylistMutation();
  const {
    data: { data: playlistsData = [], limit, page, total } = {},
    isLoading: isPlaylistsLoading,
    refetch,
  } = useGetPlaylistsQuery({
    device: screenId,
  });
  const [deletePlaylist] = useDeletePlaylistMutation();

  const countPlaylists = playlistsData.length;

  const createPlaylistLink = useMemo(
    () =>
      replaceParams(pages.playlistCreate.path, {
        type: countPlaylists ? "new" : "default",
      }),
    [countPlaylists]
  );

  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    refetch();
  }, [refetch]);

  // set default checkbox
  useEffect(() => {
    const activePlaylist = playlistsData.find((playlist) => playlist.is_active);
    if (activePlaylist) {
      setActiveId(activePlaylist.id);
    }
  }, [playlistsData]);

  const menuItems = useMemo(
    () => [
      {
        icon: RenameIcon,
        label: "Rename",
        onClick: (e, { id }) => {
          navigate(
            replaceParams(pages.playlistEdit.path, {
              playlistId: id,
            })
          );
          dispatch(hideDrawer());
        },
      },
      {
        icon: DeleteIcon,
        label: "Delete",
        color: "text-error",
        onClick: (e, { id }) => {
          deletePlaylist(id);
          dispatch(hideDrawer());
        },
      },
    ],
    []
  );

  const handleChangeStatus = (playlistId, deviceId) => (e, value) => {
    setActiveId(value && playlistId);

    changeStatus({
      playlistId,
      deviceId,
      status: value,
    });
  };

  const handleOpenMenu = ({ id, name }) => {
    dispatch(
      showDrawer({
        content: PLAYLIST_ACTIONS,
        headerProps: {
          title: name,
        },
        data: {
          menuItems,
          id,
        },
      })
    );
  };

  return (
    // todo add name
    <CommonTemplate
    // headerProps={
    //   {
    //     title: name,
    //   }
    // }
    >
      <div className="flex flex-col gap-y-4">
        <Section title="Device Management">
          {playlistsData.map(({ id, name, previews = [] }) => (
            <Playlist
              key={id}
              name={name}
              videos={previews}
              footerRightContent={
                <div className="flex items-center gap-3">
                  <Switch
                    checked={activeId === id}
                    onChange={handleChangeStatus(id, screenId)}
                  />
                  <IconButton
                    aria-label="more"
                    onClick={() => handleOpenMenu({ id, name })}
                  >
                    <MoreIcon />
                  </IconButton>
                </div>
              }
            />
          ))}
        </Section>
        <Button
          variant="contained"
          startIcon={<PlusIcon className="w-4" />}
          component={NavLink}
          to={createPlaylistLink}
        >
          Add Playlists
        </Button>
      </div>
    </CommonTemplate>
  );
};

export default Screen;
