import { NavLink } from "react-router-dom";

import Title from "components/Title";
import Button from "ui/Button";

import pages from "router/links";

import styles from "./styles.module.scss";

const Error404Page = () => {
  return (
    <div className={styles.app}>
      <div className={styles.container}>
        <div className={styles.statusWrapper}>
          <span className={styles.status}>404</span>
          <Title size="2xl" className={styles.message}>
            Oops! Page not found
          </Title>
        </div>
      </div>
      <Button
        variant="contained"
        size="small"
        component={NavLink}
        to={pages.home.path}
      >
        Return to Home
      </Button>
    </div>
  );
};

export default Error404Page;
