import Title from "components/Title";
import PlugStoreIcon from "assets/images/plugStore.svg";

import Skeleton from "ui/Skeleton";

const Card = ({ title, description, component, ...props }) => {
  const Component = component || "div";
  return (
    <Component className="flex flex-col gap-3" {...props}>
      {/* label img*/}
      <div className="flex grow rounded-[8px] overflow-hidden aspect-[292/200]">
        <img src={PlugStoreIcon} alt="" className="w-full" />
      </div>

      {/* label titles*/}
      <div>
        <Title>{title}</Title>
        <div className="truncate whitespace-nowrap">{description}</div>
      </div>
    </Component>
  );
};

Card.Skeleton = () => (
  <div className="flex flex-col gap-3">
    <Skeleton.Round
      sx={{
        aspectRatio: "16/9",
        width: "100%",
        height: "auto",
      }}
    />
    <div>
      <Skeleton width="60%" />
      <Skeleton width="90%" />
    </div>
  </div>
);

export default Card;
